import {
  FiCalendar,
  FiClock,
  FiFile,
} from 'react-icons/fi';
import { LuMessagesSquare } from 'react-icons/lu';
import resources from './i18n';

export const rootPath = '/events';

const config = {
  name: 'events',
  rootPath,
  i18n: {
    resources,
  },
  modules: {
    ComponentTenantModuleEventBasic: {
      navMenuItems: [
        {
          title: 'modules.event.title',
          attribute: 'navItemEvents',
        },
      ],
    },
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.events.title'),
        acl: [
          'api::event.event.find',
        ],
        items: [
          {
            module: 'events',
            title: t('modules.events.menu.events'),
            path: `${rootPath}`,
            type: 'ComponentTenantModuleEventBasic',
            icon: <FiCalendar />,
            attribute: 'navItemEvents',
            acl: [
              'api::event.event.find',
            ],
          },
          {
            module: 'allFeedback',
            title: t('modules.events.menu.feedback'),
            path: `${rootPath}/feedback`,
            type: 'ComponentTenantModuleEventFeedback',
            icon: <LuMessagesSquare />,
            acl: [
              'api::event.event.isEmployee',
              'api::event-feedback.event-feedback-note.find',
            ],
          },
          {
            module: 'reports',
            title: t('modules.events.menu.reports'),
            path: `${rootPath}/reports`,
            type: 'ComponentTenantModuleReport',
            icon: <FiFile />,
            acl: [
              'api::event.event.isEmployee',
              'api::report.report.find',
            ],
          },
          {
            module: 'eventSchedule',
            title: t('modules.events.menu.eventSchedule'),
            path: `${rootPath}/event-schedule`,
            type: 'ComponentTenantModuleEventSchedule',
            icon: <FiClock />,
            acl: [
              'api::event.event.isEmployee',
              'api::event-schedule.event-schedule.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
